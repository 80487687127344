import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation,
    Navigate,
} from "react-router-dom";

import { CircularProgress, Col, Content } from '@gower/react-ui/components';
import { Page404 } from '@gower/react-ui/template/page404';
import { RoutesEnum } from './routes';

import { Layout } from '../layout';
import { HomeView } from 'views/home';


export const Router = () => {

    //const { check, checkIsAdmin } = useCheckSession();
    const [loading, setLoading] = React.useState( true );
    //const [isAdmin, setIsAdmin] = React.useState( false );

    React.useEffect( () => {

        ( async () => {
            //await check();
            //setIsAdmin( checkIsAdmin() );
            setLoading( false );
        } )();

    }, [] );

    if ( loading )
        return <Loading />;

    return <>
        <BrowserRouter>
            <Routes>
                <Route path={RoutesEnum.Base} element={<RequireAuth Element={Layout} />} >
                    <Route path={RoutesEnum.Base} element={<HomeView />} />
                    <Route path={RoutesEnum.Home} element={<HomeView />} />
                    <Route path='*' element={<NotFoundPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </>;
};


type RequireAuthProps = {
    reverse?: boolean;
    Element?: any;
};
const RequireAuth: React.FC<RequireAuthProps> = ( { Element, reverse } ) => {
    //const isAuthenticated = CredentialProvider.isAuthenticated;
    //const location = useLocation();
//
    //if ( !reverse && !isAuthenticated )
    //    return <Navigate to={RoutesEnum.Login} state={{ from: location }} replace />;
//
    //if ( reverse && isAuthenticated )
    //    return <Navigate to={RoutesEnum.Home} state={{ from: location }} replace />;

    return <Element />;
};

const NotFoundPage: React.FC = () => {
    return (
        <Content fullWidth fullHeight justifyCenter>
            <Col center>
                <Page404 />
            </Col>
        </Content>
    );
};

const Loading: React.FC = () => {
    return (
        <Content
            height="100vh"
            width="100vw"
            justifyCenter
        >
            <Col center textColor="primary">
                <CircularProgress
                    color="inherit"
                    size={36}
                    thickness={5}
                />
            </Col>
        </Content>
    );
};