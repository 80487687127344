import React from 'react';
import { Col, Content } from '@gower/react-ui/components';
import { useStore, provider } from './store';

export const HomeView = provider( () => {

    return (
        <HomeTemplate />
    );

}, { viewName: 'HomeView' } );

const HomeTemplate = () => {

    const store = useStore();

    return <>
        <Content columns scrollOnlyVertical>
            <Col
                padding={10}
                boxShadow="box-shadow-2"
                height={100}
                color="blue-6"
            >
                Header!
            </Col>
            <Col
                backgroundColorCss="#337678"
                height="80%"
            >
                1!
            </Col>
            <Col
                
                height="180%"
            >
                2!
            </Col>
            <Col color="blue-9" height={120}>
                Footer!
            </Col>
        </Content>
    </>;
};