import { configureFromObject as sharedConfigureFromObject, configurePreExecuteMethod as sharedConfigurePreExecuteMethod } from '@gower/shared-sdk';

const BackendHost = '/api';

( function configure () {
    
    sharedConfigureFromObject( {
        CORE_HOST: BackendHost,
        PRINTER_HOST: BackendHost,
        ACCOUNTS_HOST: BackendHost,
    } );


    sharedConfigurePreExecuteMethod( async ( req ) => {
        // if ( CredentialsProvider.isAuthenticated ) {
        //     req.headers['authorization'] = `Bearer ${ await CredentialsProvider.getToken() }`;
        // }
        return true;
    } );
} )();